<template>
  <section>
    <b-row align-v="center">
      <!-- Nav -->
      <b-col>
        <nav-items
          pills
          class="nav-nowrap"
          :nav-items="navItems"
          :top-level-route="topLevelRoute"
        />
      </b-col>

      <!-- Season -->
      <b-col
        md="3"
        lg="2"
      >
        <select-season :disabled="$route.meta.disableSeason" />
      </b-col>
    </b-row>

    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <router-view />
    </transition>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import NavItems from '@/components/NavItems.vue'
import SelectSeason from '@/components/forms/selects/SelectSeason.vue'

import routes from '@/router/league'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  name: 'EventManage',
  components: {
    BRow,
    BCol,
    NavItems,
    SelectSeason,
  },
  setup() {
    const { routerTransition } = useAppConfig()

    return {
      // App Config
      routerTransition,
    }
  },
  data() {
    return {
      topLevelRoute: 'event.manage',
    }
  },
  computed: {
    navItems() {
      return routes.find(route => route.path === '/leagues' && route.children)
        .children.find(route => route.name === this.topLevelRoute)
        .children.filter(route => route.navTitle)
    },
  },
}
</script>

<style scoped>

</style>
